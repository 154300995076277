@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100,400;500;600;700&display=swap");

@layer base {
  * {
    font-family: "Poppins", sans-serif;
  }

  body {
    @apply bg-dark-600;
  }

  a {
    @apply text-yellow underline;
  }

  p,
  span {
    @apply text-gray-400;
  }

  .main {
    @apply my-8 lg:my-16;
  }

  .layout {
    @apply max-w-7xl m-auto px-4 md:px-16 lg:px-32;
  }

  .layout-compact {
    @apply max-w-3xl m-auto px-4 md:px-16 lg:px-32;
  }

  .text-inherit {
    color: inherit;
  }
}

/* Tools */
.MuiMenu-paper.MuiPaper-elevation {
  z-index: 10;
}

/* Select */
.MuiSelect-root {
  @apply text-white;
}

.MuiSelect-root .MuiSelect-select {
  @apply text-gray-600;
  -webkit-text-fill-color: inherit;
}

.MuiFormControl-root .MuiSelect-select {
  @apply text-gray-400;
  -webkit-text-fill-color: inherit;
}

.MuiFormControl-root .MuiSvgIcon-root {
  @apply text-gray-400;
  -webkit-text-fill-color: inherit;
}

.MuiSelect-root .MuiSvgIcon-root {
  @apply text-white;
}

.MuiFormControl-root .MuiOutlinedInput-root .MuiInputAdornment-root .MuiButtonBase-root {
  @apply text-white;
}

.MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  @apply border-yellow;
}

/* End selected */

/* Autocomplete */
.MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiButtonBase-root {
  @apply text-white;
}

/* End autocomplete */

/* TextField */

.MuiOutlinedInput-root {
  @apply bg-dark-300;
}

.MuiTextField-root {
}

.MuiFormControl-root input {
  @apply text-gray-600;
}

.MuiTextField-root input {
  @apply text-gray-600;
}

.MuiFormControl-root .MuiFormLabel-root {
  @apply text-white;
}

.MuiFormControl-root .MuiFormHelperText-root {
  @apply   text-xs text-gray-800;
}

.MuiTextField-root .MuiFormLabel-root {
  @apply text-white;
}

.MuiTextField-root .MuiOutlinedInput-notchedOutline {
}

.MuiTextField-root .Mui-disabled .MuiOutlinedInput-root {
  @apply bg-dark-400;
}

.MuiSelect-root.Mui-disabled {
  @apply text-dark-300;
}

.MuiSelect-root.Mui-disabled .MuiSelect-select.Mui-disabled {
  -webkit-text-fill-color: inherit ;
  @apply bg-dark-500 text-gray-500 cursor-not-allowed;  
}

.MuiSelect-root.Mui-disabled .MuiSvgIcon-root{
  @apply text-gray-300;
}

.MuiTextField-root .Mui-disabled input {
  @apply text-gray-800 rounded-md;
  -webkit-text-fill-color: inherit;
}

.MuiTextField-root .Mui-disabled.MuiFormLabel-root {
  @apply text-gray-800;
}

.MuiTextField-root .MuiInputLabel-asterisk {
  @apply text-yellow;
}

.MuiTextField-root .MuiTypography-root {
  @apply text-white;
}

.MuiFormControl-root .MuiInputLabel-asterisk {
  @apply text-yellow;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

/* End TextField */

/* Button */
.MuiButton-root {
  @apply font-bold;
  font-weight: bold !important;
}

.MuiButton-root .MuiButton-text{
  @apply font-bold;

}

.MuiButton-root.btn {
  @apply px-8 py-4;
}

.MuiButton-root.btn-yellow {
  @apply bg-yellow text-dark-400 shadow-yellow;
}

.MuiButton-root.btn-yellow .MuiButton-label {
  @apply text-dark-400 font-bold;
}

.MuiButton-root.btn-yellow:hover {
  @apply bg-yellow-700;
}

.MuiButton-root.btn-yellow.Mui-disabled {
  @apply bg-dark-100 text-white;
}

.btn {
  @apply px-8 py-4 rounded-md font-semibold;
}

.btn.btn-block {
  @apply block w-full;
}

.btn.btn-yellow {
  @apply bg-yellow text-dark-400 shadow-yellow;
}

.btn.btn-dark {
  @apply bg-dark-300 text-dark-100 shadow-yellow;
}

.btn.btn-dark:focus {
  @apply bg-dark-300;
}

.btn.btn-dark:hover {
  @apply bg-dark-200;
}

/* End Button */

/* A Button */
a.btn {
  @apply flex items-center no-underline;
}

a.btn.btn-yellow {
  @apply bg-yellow text-dark-600;
}

/* END A Button */

/*Checkbox */
.MuiCheckbox-root .MuiSvgIcon-root {
  @apply text-gray-800;
}

.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  @apply text-yellow;
}
/*END Checkbox */

/*Backdrop */
.MuiBackdrop-root {
  z-index: 10 !important;
  @apply bg-yellow-100 bg-opacity-60;
}

/*END Backdrop*/

/* Dialog */
.MuiPaper-root.MuiDialog-paper {
  z-index: 100 !important;
  @apply bg-dark-400 text-gray-400;
}
.MuiPaper-root.MuiDialog-paper {
  @apply bg-dark-600;
}
/* Dialog */

/* Toogle buttons */

.MuiToggleButtonGroup-root .MuiButtonBase-root.pill {
  @apply bg-dark-300 text-dark-100 rounded-full px-4 py-0 m-0 capitalize;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.pill.pill-border {
  @apply border-dark-100;
  border-left: 1px solid !important;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.pill .MuiToggleButton-label {
  @apply text-gray-600;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.pill.Mui-selected {
  @apply text-yellow;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.pill.pill-border.Mui-selected {
  @apply border-l border-yellow;
  border-left: 1px solid !important;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.pill.pill-fill.Mui-selected {
  @apply bg-yellow text-dark-500;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.pill.Mui-selected .MuiToggleButton-label {
  @apply text-dark-300;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.square {
  @apply bg-dark-500 text-white rounded-full px-4 py-0 m-0 capitalize;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.square.square-border {
  @apply border-yellow;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.square .MuiToggleButton-label {
  @apply text-gray-600;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.square.Mui-selected {
  @apply bg-yellow text-dark-500;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.square.square-border.Mui-selected {
  @apply border-l border-yellow;
  border-left: 1px solid !important;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.square.square-fill.Mui-selected {
  @apply bg-yellow text-dark-500;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.square.Mui-selected .MuiToggleButton-label {
  @apply text-dark-300;
}

/* gradiant-range-group */
.gradiant-range-group .MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
  @apply bg-dark-100 rounded-md text-white;
}
/* end gradiant-range-group */

/* Stepper */

.MuiStepper-root.stepper-rounded {
}

.MuiStepper-root.stepper-rounded .MuiButtonBase-root.disabled {
  @apply bg-gray-800 text-dark-600;
}

.MuiStepper-root.stepper-rounded .MuiButtonBase-root.enable {
  @apply bg-yellow text-dark-600;
}

.MuiStepper-root.stepper-rounded .MuiButtonBase-root.active {
  @apply bg-yellow text-dark-600;
}

.MuiStepper-root.stepper-rounded .MuiStepConnector-root.Mui-completed {
  @apply border-yellow;
}

.MuiStepper-root.stepper-rounded .MuiStepConnector-root.Mui-active {
  @apply border-yellow;
}

.MuiStepper-root.stepper-rounded .MuiStepConnector-root.Mui-active .MuiStepConnector-line,
.MuiStepper-root.stepper-rounded .MuiStepConnector-root.Mui-completed .MuiStepConnector-line {
  @apply border-yellow;
}

.MuiStepper-root.stepper-rounded .MuiStepConnector-root {
  top: 30px;
  border-top-width: 3px;
  left: calc(-50% + 30px);
}

/* Text editor */
.DraftEditor-editorContainer .rdw-mention-link {
  @apply border-yellow rounded-md bg-transparent;
}

.DraftEditor-editorContainer .rdw-mention-link span {
  @apply text-yellow;
}

.rdw-suggestion-dropdown .rdw-suggestion-option {
  @apply text-dark-600;
}


.demo-editor.rdw-editor-main {
  padding-bottom: 150px;
}

/*
.MuiDataGrid-panel .MuiPaper-root.MuiPaper-elevation {
  @apply bg-dark-300;
}
*/

@keyframes env-gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
